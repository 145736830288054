




































import {Component, Mixins} from 'vue-property-decorator';
import {
    BModal,
    BButton
} from 'bootstrap-vue';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {
        BModal,
        BButton
    }
})
export default class QuorumIntersectionInfo extends Mixins(StoreMixin) {
    hideModal() {
        (this.$refs['network-analysis-qi-info'] as any).hide();
    }
}
