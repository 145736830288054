






















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {
    BFormInput,
    BModal,
    BButton,
    BFormCheckbox,
    BFormSelect,
    BTable,
    BPagination,
    BBadge,
    BCard,
    BCardBody,
    BCardText,
    BCollapse,
    VBToggle,
    BCardHeader
} from 'bootstrap-vue';
import {Node, PublicKey} from '@stellarbeat/js-stellar-domain';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {
        BFormInput,
        BModal,
        BButton,
        BFormCheckbox,
        BFormSelect,
        BTable,
        BPagination,
        BBadge,
        BCard,
        BCardBody,
        BCardText,
        BCollapse,
        BCardHeader
    },
    directives: {'b-toggle': VBToggle}
})
export default class Analysis extends Mixins(StoreMixin) {
    protected merge: boolean = false;

    @Prop()
    merged!:boolean;
    @Prop()
    items!: any;
    @Prop()
    mergedItems!: any;
    @Prop()
    fields: any;

    protected perPage: number = 5;
    protected currentPage: number = 1;

    get rows() {
        return this.merged ? this.mergedItems.length : this.items.length;
    }
}
