














































































































































































import {Component, Mixins} from 'vue-property-decorator';
import {
    BIconX,
    BFormInput,
    BAlert,
    BButton,
    BFormCheckbox,
    BFormSelect,
    BTable,
    BPagination,
    BBadge,
    BCard,
    BCardBody,
    BCardFooter,
    BCardText,
    BCollapse,
    VBToggle,
    BCardHeader,
    BIconInfoCircle, VBModal, VBTooltip
} from 'bootstrap-vue';
import {StoreMixin} from '@/mixins/StoreMixin';
import FbasAnalysisWorker from 'worker-loader?name=worker/[name].js!./../../../../workers/fbas-analysis-v2.worker.ts';
import {IsLoadingMixin} from '@/mixins/IsLoadingMixin';
import {Node, PublicKey} from '@stellarbeat/js-stellar-domain';
import Analysis from '@/components/network/tools/network-analysis/analysis.vue';
import QuorumIntersectionInfo from '@/components/network/tools/network-analysis/info/quorum-intersection-info.vue';
import SafetyInfo from '@/components/network/tools/network-analysis/info/safety-info.vue';
import LivenessInfo from '@/components/network/tools/network-analysis/info/liveness-info.vue';
import TopTierInfo from '@/components/network/tools/network-analysis/info/top-tier-info.vue';

const _FbasAnalysisWorker: any = FbasAnalysisWorker; // workaround for typescript not compiling web workers.

@Component({
    components: {
        TopTierInfo,
        LivenessInfo,
        SafetyInfo,
        QuorumIntersectionInfo,
        BIconX,
        Analysis,
        BFormInput,
        BButton,
        BAlert,
        BFormCheckbox,
        BFormSelect,
        BTable,
        BPagination,
        BBadge,
        BCard,
        BCardBody,
        BCardFooter,
        BCardText,
        BCollapse,
        BCardHeader,
        BIconInfoCircle
    },
    directives: {'b-toggle': VBToggle, 'b-modal': VBModal, 'b-tooltip': VBTooltip}
})
export default class NetworkAnalysis extends Mixins(StoreMixin, IsLoadingMixin) {
    protected fbasAnalysisWorker = new _FbasAnalysisWorker();
    protected mergeByOrganizations: boolean = this.$root.$data.store.network.nodes.length > 20;
    protected hasResult: boolean = false;
    protected resultIsMerged: boolean = true;
    protected hasQuorumIntersection: boolean = false;
    protected minimalQuorums: { value: PublicKey[], text: string }[] = [];
    protected minimalQuorumsMerged: { value: string[], text: string }[] = [];
    protected minimalQuorumFields: any = [
        // A column that needs custom formatting
        {key: 'minimalQuorums', label: 'Minimal quorums'}
    ];

    protected blockingSets: { value: PublicKey[], text: string }[] = [];
    protected blockingSetsMinLength: number = 0;
    protected blockingSetsMerged: { value: string[], text: string }[] = [];
    protected blockingSetsMergedMinLength: number = 0;
    protected blockingSetsFields: any = [
        // A column that needs custom formatting
        {key: 'blockingSets', label: 'Minimal blocking sets'}
    ];

    protected splittingSets: { value: PublicKey[], text: string }[] = [];
    protected splittingSetsMinLength: number = 0;
    protected splittingSetsMerged: { value: string[], text: string }[] = [];
    protected splittingSetsMergedMinLength: number = 0;
    protected splittingSetsFields: any = [
        // A column that needs custom formatting
        {key: 'splittingSets', label: 'Minimal splitting sets'}
    ];

    protected topTier: string[] = [];
    protected topTierIsSymmetric: boolean = false;

    get topTierFields() {
        return [
            // A column that needs custom formatting
            {key: 'topTier', label: this.resultIsMerged ? 'Organizations' : 'Validators'}
        ];
    }

    get correctlyConfiguredNodes(){
        let isNodeCorrectlyConfigured = (node:Node) => {
            return !(node.quorumSet.validators.length === 1
                && node.publicKey === node.quorumSet.validators[0]
                && node.quorumSet.innerQuorumSets.length === 0);
        }

        return this.network.nodes.filter(node => isNodeCorrectlyConfigured(node));
    }

    performAnalysis() {
        this.isLoading = true;
        this.fbasAnalysisWorker.postMessage({
            nodes: this.correctlyConfiguredNodes,
            organizations: this.network.organizations,
            mergeByOrganizations: this.mergeByOrganizations,
            failingNodePublicKeys: this.network.nodes.filter(node => this.network.isNodeFailing(node)).map(node => node.publicKey)
        });
    }

    async mounted() {
        this.isLoading = false;
        this.$nextTick(() => {
            this.$scrollTo('#network-analysis-card');
        });
        this.fbasAnalysisWorker.onmessage = (
            event: { data: { type: string, result: any } }
        ) => {
            switch (event.data.type) {
                case 'tick': {

                }
                    break;
                case 'end': {
                    if (event.data.result) {
                        this.hasResult = true;
                        this.resultIsMerged = true;
                        this.hasQuorumIntersection = event.data.result.organizationAnalysis.has_intersection;
                        this.minimalQuorumsMerged = JSON.parse(event.data.result.organizationAnalysis.minimal_quorums)
                            .map((quorum: string[]) => {
                                return {
                                    'minimalQuorums': quorum.join(', ')
                                };
                            });
                        let blockingSetsMerged = JSON.parse(event.data.result.organizationAnalysis.minimal_blocking_sets);
                        if (blockingSetsMerged.length > 0) {
                            this.blockingSetsMergedMinLength = blockingSetsMerged[0].length;
                            this.blockingSetsMerged = blockingSetsMerged
                                .map((blockingSet: string[]) => {
                                    return {
                                        'blockingSets': blockingSet.join(', ')
                                    };
                                });
                        }
                        let splittingSetsMerged = JSON.parse(event.data.result.organizationAnalysis.minimal_splitting_sets);
                        if (splittingSetsMerged.length > 0) {
                            this.splittingSetsMergedMinLength = splittingSetsMerged[0].length;
                            this.splittingSetsMerged = splittingSetsMerged
                                .map((splittingSet: string[]) => {
                                    return {
                                        'splittingSets': splittingSet.join(', ')
                                    };
                                });
                        }

                        this.topTier = event.data.result.organizationAnalysis.top_tier.map((top:string) => {
                            return {
                                'topTier': top
                            }
                        });
                        this.topTierIsSymmetric = event.data.result.organizationAnalysis.symmetric_top_tier_exists;

                        if (!event.data.result.merged) {
                            this.resultIsMerged = false;
                            this.hasQuorumIntersection = event.data.result.nodesAnalysis.has_intersection; //quorum intersection based on nodesAnalysis is more accurate
                            this.minimalQuorums = JSON.parse(event.data.result.nodesAnalysis.minimal_quorums)
                                .map((quorum: PublicKey[]) => {
                                    let quorumPretty = quorum.map(publicKey => this.network.getNodeByPublicKey(publicKey)!.displayName).join(', ');
                                    return {
                                        'minimalQuorums': quorumPretty
                                    };
                                });


                            let blockingSets = JSON.parse(event.data.result.nodesAnalysis.minimal_blocking_sets);
                            if (blockingSets.length > 0) {
                                this.blockingSetsMinLength = blockingSets[0].length;
                                this.blockingSets = blockingSets
                                    .map((blockingSet: PublicKey[]) => {
                                        let blockingSetPretty = blockingSet.map(publicKey => this.network.getNodeByPublicKey(publicKey)!.displayName).join(', ');
                                        return {
                                            'blockingSets': blockingSetPretty
                                        };
                                    });
                            }


                            let splittingSets = JSON.parse(event.data.result.nodesAnalysis.minimal_splitting_sets);
                            if (splittingSets.length > 0) {
                                this.splittingSetsMinLength = splittingSets[0].length;
                                this.splittingSets = splittingSets
                                    .map((splittingSet: PublicKey[]) => {
                                        let splittingSetPretty = splittingSet.map(publicKey => this.network.getNodeByPublicKey(publicKey)!.displayName).join(', ');
                                        return {
                                            'splittingSets': splittingSetPretty
                                        };
                                    });
                            }

                            this.topTier = event.data.result.nodesAnalysis.top_tier
                                .map((top:string) => this.network.getNodeByPublicKey(top)!.displayName)
                                .sort()
                                .map((top:string) => {
                                return {
                                    'topTier': top
                                }
                            })
                            this.topTierIsSymmetric = event.data.result.nodesAnalysis.symmetric_top_tier_exists;
                        }

                    }

                    this.isLoading = false;
                }
                    break;
            }
        };
    }
}
